<template>
  <!-- add-member Modal -->
  <div
    class="modal fade"
    id="addMemberModal"
    tabindex="-1"
    role="dialog"
    aria-labelledby="addMemberModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="addMemberModalLabel">Add Team Member</h5>
          <a
            class="close"
            data-dismiss="modal"
            aria-label="Close"
            v-on:click="cancelCrop"
          >
            <span aria-hidden="true"
              ><img
                class="img-fluid"
                src="../../assets/img/23. Closex.svg"
                alt=""
            /></span>
          </a>
        </div>
        <form v-on:submit.prevent="addNewTeamMember">
          <div class="modal-body">
            <div v-show="doCrop">
              <vue-cropper
                :aspect-ratio="1 / 1"
                :view-mode="1"
                ref="cropper"
                :src="cropImgUrl"
                alt="Source Image"
              ></vue-cropper>
            </div>
            <div v-show="!doCrop">
              <div class="user-img">
                <div class="button-wrapper">
                  <span class="label">
                    <img class="img-fluid" v-bind:src="imgUrl" alt="" /> </span
                  ><input
                    type="file"
                    ref="file"
                    accept="image/*"
                    v-on:change="handleFileUpload"
                    class="upload upload-box"
                    placeholder="Upload File"
                  />
                </div>
                <!-- <div class="edit-pro">
                                <img class="img-fluid" src="../../assets/img/edit.svg" alt="">
                            </div> -->
              </div>
              <div class="add-member">
                <div class="form-row"></div>
                <div class="form-row">
                  <div class="form-group col-md-6">
                    <label for="first_name">First Name</label>
                    <input
                      v-model="register_.first_name"
                      type="text"
                      class="form-control"
                      id="first_name"
                      placeholder="Jeanne"
                    />
                    <div
                      class="validate-error-message"
                      v-if="$vd.register_.first_name.$hasError === true"
                    >
                      {{ $vd.register_.$errors.first_name[0] }}
                    </div>
                  </div>
                  <div class="form-group col-md-6">
                    <label for="last_name">Last Name</label>
                    <input
                      v-model="register_.last_name"
                      type="text"
                      class="form-control"
                      id="last_name"
                      placeholder="Acevedo"
                    />
                    <div
                      class="validate-error-message"
                      v-if="$vd.register_.last_name.$hasError === true"
                    >
                      {{ $vd.register_.$errors.last_name[0] }}
                    </div>
                  </div>
                </div>
                <div class="form-group">
                  <label for="email">Email Address</label>
                  <input
                    v-model="register_.email"
                    type="email"
                    class="form-control"
                    id="email"
                    placeholder="example@gmail.com"
                  />
                  <div
                    class="validate-error-message"
                    v-if="$vd.register_.email.$hasError === true"
                  >
                    {{ $vd.register_.$errors.email[0] }}
                  </div>
                  <div class="validate-error-message" v-if="invalid_email">
                    The email has already been taken.
                  </div>
                </div>
                <div class="form-row">
                  <div class="form-group col-md-6">
                    <label for="password">Password</label>
                    <input
                      v-model="register_.password"
                      type="password"
                      class="form-control"
                      id="password"
                      placeholder="Enter password"
                    />
                    <div
                      class="validate-error-message"
                      v-if="$vd.register_.password.$hasError === true"
                    >
                      {{ $vd.register_.$errors.password[0] }}
                    </div>
                  </div>
                  <div class="form-group col-md-6">
                    <label for="cpassword">Confirm Password</label>
                    <input
                      v-model="register_.cpassword"
                      type="password"
                      class="form-control"
                      id="cpassword"
                      placeholder="Again enter password "
                    />
                    <div
                      class="validate-error-message"
                      v-if="$vd.register_.cpassword.$hasError === true"
                    >
                      {{ $vd.register_.$errors.cpassword[0] }}
                    </div>
                  </div>
                </div>
                <div class="form-group">
                  <label>Phone number</label>
                  <!-- <vue-tel-input v-model="register_.phone" v-bind="{mode:'international',dynamicPlaceholder:true,inputId:'phone',enabledCountryCode:false,maxLen:15,validCharactersOnly:true,onlyCountries:['us'],defaultCountry:'us',placeholder:'Enter a phone number'}" ></vue-tel-input> -->
                  <input
                    type="tel"
                    v-model="register_.phone"
                    class="form-control"
                  />
                  <div
                    class="validate-error-message"
                    v-if="$vd.register_.phone.$hasError === true"
                  >
                    {{ $vd.register_.$errors.phone[0] }}
                  </div>
                  <!-- <input v-model="register_.phone" type="text" class="form-control" id="phone" placeholder="+123-22-223-00"> -->
                </div>
                <div class="form-group">
                  <label for="role_title">Role</label>
                  <select class="form-control" v-model="register_.role">
                    <option value="" disabled>Select Role</option>
                    <option
                      v-for="role in roles"
                      :key="role.id"
                      :value="role.id"
                    >
                      {{ role.role_title }}
                    </option>
                  </select>
                  <div
                    class="validate-error-message"
                    v-if="$vd.register_.role.$hasError === true"
                  >
                    {{ $vd.register_.$errors.role[0] }}
                  </div>
                </div>
                <!-- <div class="form-group">
                                <label>Role</label>
                                <select class="form-control" v-model="register_.role" >
                                    <option value="">Select user role</option>
                                    <option v-for="role in roles" :value="role.id" :key="role.id">{{role.role_title}}</option>
                                </select>
                                <div class="validate-error-message" v-if="$vd.register_.role.$hasError === true">{{$vd.register_.$errors.role[0]}}</div>
                            </div> -->

                <div class="form-group">
                  <multi-select
                    :searchable="true"
                    id="lead_permissions"
                    textColumn="text"
                    :options="permissions"
                    v-model="register_.permissions"
                    label="Permissions"
                    placeholder="Add permissions"
                  ></multi-select>
                  <!-- <div class="validate-error-message">
                    {{ permissionerror }}
                  </div> -->
                </div>
                <div class="form-group">
                  <multi-select
                    :searchable="true"
                    id="lead_source"
                    textColumn="ls_name"
                    placeholder="Select your lead source"
                    v-model="register_.lsources"
                    :options="LSources"
                    label="Lead source"
                  ></multi-select>
                  <!-- <div class="validate-error-message">{{ lsourceerror }}</div> -->
                </div>

                <!-- <div class="form-group">
                                <div class="Permis-btns">
                                    <a class="Permis-temp" href="#">Temporarily suspended</a>
                                    <a class="Permis-assing" href="#" data-toggle="modal" data-target="#exampleModal2" data-dismiss="modal" aria-label="Close">Assign</a>
                                </div>
                            </div> -->
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <!-- <a href="#" class="add-btn"><img class="img-fluid" src="../../assets/img/22. Delete.svg" alt=""> Cancel</a> -->
            <button type="submit" class="rem-btn" v-if="!doCrop">
              <img
                class="img-fluid"
                src="../../assets/img/23. Close.svg"
                alt=""
              />
              Add
            </button>
            <a
              href="#"
              v-if="doCrop"
              data-dismiss="modal"
              v-on:click="cancelCrop"
              class="add-btn"
              ><img
                class="img-fluid"
                src="../../assets/img/22. Delete.svg"
                alt=""
              />
              Cancel</a
            >
            <a v-on:click="cropIt" class="rem-btn" v-if="doCrop">Crop</a>
          </div>
        </form>
      </div>
    </div>
  </div>
  <!-- add-member Modal -->
</template>
<script>
import VueDaval from "vue-daval";
import axios from "axios";
import $ from "jquery";
//import Select2 from 'v-select2-component';
import VueCropper from "vue-cropperjs";
import "cropperjs/dist/cropper.css";

import MultiSelect from "../helpers/MultiSelectWithCheckbox";

export default {
  name: "AddTeamMember",
  components: { VueCropper, MultiSelect },
  props: ["LSources", "permissions"],
  mixins: [VueDaval],
  data() {
    return {
      isActive: false,
      roles: [],
      register_: {
        img: "",
        first_name: "",
        last_name: "",
        email: "",
        password: "",
        cpassword: "",
        phone: "",
        permissions: [],
        lsources: [],
        team: this.$route.params.slug,
        role: "",
      },
      imgUrl: require("../../assets/img/profile.svg"),
      cropImgUrl: null,
      doCrop: false,
      invalid_email: false,
    };
  },
  vdRules: {
    register_: {
      first_name: { required: true },
      last_name: { required: true },
      email: { required: true, type: "email" },
      password: { required: true, minlen: 8 },
      cpassword: { required: true, equals: "register_.password" },
      phone: { required: true },
      role: { required: true },
    },
  },
  created() {
    this.getRoles();

    let vs = this;
    $("body").mouseup(function (e) {
      var container = $(".leadsources");
      if (!container.is(e.target) && container.has(e.target).length === 0) {
        vs.isActive = false;
      }
    });
    this.$root.$on("action", function (action) {
      if (action == "checkallpermisssions") {
        vs.register_.permissions = vs.permissions;
      }
      if (action == "unselectallpermisssions") {
        vs.register_.permissions = [];
      }
      if (action == "checkallsources") {
        vs.register_.lsources = vs.LSources;
      }
      if (action == "unselectallsources") {
        vs.register_.lsources = [];
      }
    });
  },
  mounted() {
    this.register_.email = "example@gmail.com";
    this.register_.permissions = [];
    this.register_.lsources = [];
  },
  methods: {
    addNewTeamMember() {
      console.log();
      this.$vd.register_
        .$validate()
        .then(() => {
          axios
            .post("add_team_member/2", this.register_, {
              headers: {
                Authorization: "Bearer " + this.$storage.get("auth").token,
                "X-Requested-With": "XMLHttpRequest",
              },
            })
            .then((res) => {
              if (res.data.success) {
                this.invalid_email = false;
                $("#addMemberModal").modal("hide");
                //this.$root.$emit('newMember', res.data.success)
                this.$root.$emit("newMember", "");
                window.location.href = "";
                this.register_ = {
                  img: "",
                  first_name: "",
                  last_name: "",
                  email: "",
                  password: "",
                  cpassword: "",
                  phone: "",
                  lsources: "",
                  team: this.$route.params.slug,
                  role: "",
                  permissions: "",
                };
              } else if (res.data.validate) {
                this.invalid_email = true;
              }
            })
            .catch((err) => {
              console.log(err);
              this.invalid_email = false;
            });
        })
        .catch(() => {});
    },
    handleFileUpload(e) {
      const file = e.target.files[0];
      if (file.type.indexOf("image/") === -1) {
        alert("Please select an image file");
        return;
      }

      if (typeof FileReader === "function") {
        const reader = new FileReader();
        reader.onload = (event) => {
          this.cropImgUrl = event.target.result;
          // rebuild cropperjs with the updated source
          this.$refs.cropper.replace(event.target.result);
        };
        reader.readAsDataURL(file);
      } else {
        alert("Sorry, FileReader API not supported");
      }
      this.doCrop = true;

      //this.imgUrl = URL.createObjectURL(this.$refs.file.files[0]);
    },
    cropIt() {
      this.imgUrl = this.$refs.cropper.getCroppedCanvas().toDataURL();
      this.register_.img = this.$refs.cropper.getCroppedCanvas().toDataURL();
      this.doCrop = false;
    },
    cancelCrop() {
      this.doCrop = false;
    },
    getTeams() {
      axios
        .get("teams", {
          headers: {
            Authorization: "Bearer " + this.$storage.get("auth").token,
            "X-Requested-With": "XMLHttpRequest",
          },
        })
        .then((res) => {
          const {
            data: { data },
          } = res;
          const allTeams = [];
          $.each(data, function (key, value) {
            allTeams.push({ id: value.tm_id, text: value.tm_name });
          });

          // this.$root.$on('newTeam', function(newTeam_data) {
          //     data.push(newTeam_data)
          // })
          this.teams = allTeams;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    getRoles() {
      axios
        .get("roles", {
          headers: {
            Authorization: "Bearer " + this.$storage.get("auth").token,
          },
        })
        .then((res) => {
          if (res.data.success) {
            this.roles = res.data.success;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    // getLeadSource(){
    // 	axios
    // 	.get('lead_sources')
    // 	.then( res => {
    //         if (res.data.success) {
    //             this.LSources = res.data.success
    //         }
    // 	})
    // 	.catch(err =>{
    // 		console.log(err)
    // 	})
    // },
  },
  computed: {
    permissionerror() {
      if (this.register_.permissions.length == 0) {
        return "this field is required ";
      } else {
        return "";
      }
    },
    lsourceerror() {
      if (this.register_.lsources.length == 0) {
        return "this field is required ";
      } else {
        return "";
      }
    },
  },
  watch: {
    "$route.params.slug": function () {
      this.register_.team = this.$route.params.slug;
    },
  },
};
</script>
<style scoped>
.vue-tel-input {
  border: 1px solid #d5d8df !important;
}
.vue-tel-input:focus-within {
  box-shadow: none;
  border-color: #3151a1 !important;
}
</style>
